const Separator = '/';

//Page name only
export const Page = {
  // Authorization
  Login: 'admin',
  Register: 'register',
  Awsauth: 'Awsauth',
  AccountCreated: 'AccountCreated',
  Forget: 'forget',

  // Dashboard
  Dashboard: 'dashboard',
  
  // Pages
  Analytics: 'analytics',
  Voters: 'search-voters-postgres',
  SearchUserClickHouse: 'search-voters-clickhouse',
  UsersManagement: 'users-management',
  VotersListing: 'voters-listing',
  LoginLogs: 'voters-listing-logs',
  VotersListingLogs: 'voters-listing-logs-old',
  AddOwnership: 'add-captain',
  CustomVotersListing: 'custom-voters-listing',
  Statistics: 'home',
  Stats: 'home/analytics',
  Main: '',
  Reasons: 'ballot-reasons',
  ChorplethMap: 'chorpleth-map',
  VoterPopulation: 'home/voter-population',
  VoterTurnout: 'home/voter-turnout',

};





//Full url of each Page
export const AmacUrl = {
  // Authorization
  Login: Separator + Page.Login,
  Statistics: Separator + Page.Statistics,
  Stats: Separator + Page.Stats,
  // Dashboard
  Dashboard: Separator + Page.Dashboard,

  //Pages
  Analytics: Separator + Page.Analytics,
  UsersManagement: Separator + Page.UsersManagement,
  VotersListing: Separator + Page.VotersListing,
  Voters: Separator + Page.Voters,
  SearchUserClickHouse: Separator + Page.SearchUserClickHouse,
  LoginLogs: Separator + Page.LoginLogs,
  VotersListingLogs: Separator + Page.VotersListingLogs,
  // MyCases: Separator + [Page.Dashboard, Page.MyCases].join(Separator),
  AddOwnership: Separator + Page.AddOwnership,
  CustomVotersListing: Separator + Page.CustomVotersListing,
  Reasons: Separator + Page.Reasons,
  ChorplethMap: Separator + Page.ChorplethMap,
  VoterPopulation: Separator + Page.VoterPopulation,
  VoterTurnout: Separator + Page.VoterTurnout,
};